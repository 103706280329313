import React from "react"
import { Helmet } from 'react-helmet'

import "../app.css"

const ExternalLink = props => <a target="_blank" rel="noopener noreferer" {...props} />

const title = 'Joel Saupe - Software Engineer'
const description = "I'm a software engineer with a bunch of experience. I write lots of JavaScript and like improving both user and developer experiences. I'm available for consulting as well as short/long-term contract and full-time opportunities"

const IndexPage = () => (
  <main>
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
      title={title}
      meta={[
        { name: 'theme-color', content: '#663399' },
        {
          name: 'description',
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: '@joelsaupe',
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
      ]}
    />

    <h1>Hi, I'm Joel. Nice to meet you.</h1>

    <div className="tldr">
      <h3>tl;dr</h3>
      <p>I do good work. <a href="mailto:joel@starrynightstudios.com">Let's work together</a>.</p>
    </div>

    <p>
      My name is Joel Saupe, I'm a software engineer from Utah. I have a bunch of experience leading frontend development at companies like <ExternalLink href="https://sendoso.com">Sendoso</ExternalLink> and <ExternalLink href="https://deseretbook.com">Deseret Book</ExternalLink>. I also do contract work and consulting at <ExternalLink href="http://starrynightstudios.com">Starry Night Studios</ExternalLink> by night. I'm currently open for new full-time opportunities!
    </p>

    <p>
      Most of the code I write is TypeScript, JavaScript, Vue, and React. I write some Ruby on Rails here and there
      as well.
    </p>


    <p>
      The things I'm most passionate about and focus a lot of my efforts on include:
    </p>
    <ul>
      <li>Accessibility</li>
      <li>User Experience</li>
      <li>Empowering Developers</li>
      <li>Great Documentation</li>
    </ul>

    <p>
      I try to contribute to open source when I can, I could probably do better, but <ExternalLink href="https://www.npmjs.com/~joelsaupe">I maintain a
      couple packages that I'm pretty proud of</ExternalLink>.
    </p>

    <p>
      Sometimes <ExternalLink href="https://webdev.tips/">I write about topics I'm interested in</ExternalLink>, or occasionally I
      give talks on things I'm passionate about. Here are some of the talks I've given:
    </p>
    <ul>
      <li>
        Solidus UI: An Exploration into UI Toolkits
        <br /><small><em>Solidus Conf 7</em></small>
      </li>
      <li>
        <ExternalLink href="https://www.youtube.com/watch?v=MJBog3hjAt4">
          Solidus API: From PWAs to Native and Beyond
        </ExternalLink>
        <br /><small><em>SolidusConf 2019</em></small>
        
      </li>
      <li>
        <ExternalLink href="https://www.youtube.com/watch?v=4jPwU-5gAUc">
          Discussion Panel: Accessible Technology
        </ExternalLink>
        <br /><small><em>UtahJS Conf 2018</em></small>
        
      </li>
      <li>
        <ExternalLink href="https://www.youtube.com/watch?v=nEGL-F5Aphs">
          Accessibility: More than Just Screen Readers and ARIA
        </ExternalLink>
        <br /><small><em>UtahJS Conf 2018</em></small>
      </li>
    </ul>

    <p>
      I'm not always working though. I love spending time with my family and <ExternalLink href="https://www.strava.com/athletes/15506504">I'm pretty into running</ExternalLink>{" "}
      as well.
    </p>

    <p>
      If you need someone to point you in the right direction, or maybe someone to jump on a
      project for a little while, I'm the right person for the job. Or maybe you're looking for someone
      to fill a full time position, then that's cool too, so email me already!
    </p>

    <a href="mailto:joel@starrynightstudios.com">
      <h3>
        Email me at joel@starrynightstudios.com
      </h3>
    </a>

  </main>
)

export default IndexPage
